import Swal from "sweetalert2";
import axios from "axios";


let NOTIFICATION_TYPES = {
    pending: 'info',
    started: 'info',
    retry: 'warning',
    failure: 'error',
    success: 'success',
}

let NOTIFICATION_MESSAGES = {
    pending: 'The task is waiting for execution.',
    started: 'The task has been started.',
    retry: 'The task is to be retried, possibly because of failure.',
    failure: 'The task exception failed.',
    success: 'The task executed successfully.',
}

export const state = {
    tasks: []
};

export const mutations = {
    ADD_TASK(state, new_task) {
        let Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
        });

        try {
            let taskExists = state.tasks.find(t => t.id === new_task.id);

            if (!taskExists) {
                state.tasks.unshift({
                    ...new_task,
                    created_at: new Date(),
                    _status: 'pending'
                });
            }
        } catch {
            alert("Failed to add task")
            return;
        }

        localStorage.setItem('tasks', JSON.stringify(state.tasks));

        const checkStatus = async (task) => {
            let status = '';
            while (!['success', 'failure'].includes(status)) {
                try {
                    let response = await axios.get(task.check_url + task.id + '/');
                    status = response.data.status.toLowerCase();

                    let taskIndex = state.tasks.findIndex(t => t.id === task.id);

                    if (taskIndex !== -1) {
                        state.tasks[taskIndex]._previous_status = state.tasks[taskIndex]._status ? state.tasks[taskIndex]._status : 'pending';
                        state.tasks[taskIndex]._status = status;
                        localStorage.setItem('tasks', JSON.stringify(state.tasks));
                        state.tasks = JSON.parse(localStorage.getItem('tasks') || [])
                    }

                    if (state.tasks[taskIndex]._status !== state.tasks[taskIndex]._previous_status) {
                        await Toast.fire({
                            type: NOTIFICATION_TYPES[status] || 'info',
                            icon: NOTIFICATION_TYPES[status] || 'info',
                            title: task.title,
                            text: NOTIFICATION_MESSAGES[status] || 'info',
                        });
                    }

                } catch {
                    console.error("Failed to check task status")
                }

                // Wait for 5 seconds before the next request
                await new Promise(resolve => setTimeout(resolve, task.every));
            }
        }
        checkStatus(new_task).then(() => {

        })
    },
    CLEAR_ALL(state) {
        state.tasks = [];
        localStorage.setItem('tasks', JSON.stringify(state.tasks));
    }
};

export const actions = {
    addTask({commit}, task) {
        commit('ADD_TASK', task);
    },
    clearAll({commit}) {
        commit('CLEAR_ALL');
    },
    initTasks({commit}) {
        try {
            state.tasks = JSON.parse(localStorage.getItem('tasks') || [])
            localStorage.setItem('tasks', JSON.stringify(state.tasks || []))

            state.tasks.filter(task => !['success', 'failure'].includes(task._status)).forEach(task => {
                setTimeout(() => {
                    commit('ADD_TASK', task);
                }, 1000)
            })
        } catch {
            state.tasks = []
            localStorage.setItem('tasks', JSON.stringify([]))
        }
    }
};


